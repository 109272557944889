import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {
  formatDate,
  formatDateTz,
  formatPrice,
} from "../../helppers/formatters"
import { Chip, IconButton, Typography } from "@mui/material"
import Lightbox from "react-18-image-lightbox"
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined"

function Row({ event }) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [photoIndex, setPhotoIndex] = React.useState(0)

  const images = React.useMemo(() => {
    if (!event?.lead) return []
    if (!event?.lead?.f_image) return event?.lead?.images?.map((x) => x?.url)
    return [
      event?.lead?.f_image?.url,
      ...event?.lead?.images?.map((x) => x?.url),
    ]
  }, [event?.lead])
  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          bgcolor: event?.isPickUp ? "inherit" : "#dcfc6fc2",
        }}
      >
        <TableCell sx={{ px: 0 }} align="center" component="th" scope="row">
          <IconButton
            onClick={() => setIsOpen(true)}
            aria-label="images"
            component="button"
          >
            <PermMediaOutlinedIcon />
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="body1" fontWeight={600}>
            {event?.lead?.title}
          </Typography>
        </TableCell>
        <TableCell align="left">{event?.lead?.vrm}</TableCell>

        <TableCell align="left">{event?.lead?.sellerName || "N/A"}</TableCell>
        <TableCell align="left">{event?.lead?.sellerPhone || "N/A"}</TableCell>
        <TableCell align="center">
          {formatDateTz(event?.start, event?.tz)}
        </TableCell>
        <TableCell
          sx={{
            whiteSpace: "pre-wrap",
          }}
          align="center"
        >
          {event?.address || "N/A"}
        </TableCell>
        <TableCell align="center">
          {formatPrice(event?.lead?.agreedPrice)}
        </TableCell>
        <TableCell align="center">
          <Chip
            label={event?.status}
            color={
              event?.status === "Pending"
                ? "info"
                : event?.status === "Deal"
                ? "success"
                : event?.status === "Lost" && "error"
            }
            sx={{ px: 1, py: 0.5 }}
            size="small"
          />
        </TableCell>
      </TableRow>
      {isOpen && images?.length > 0 && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images?.length]}
          prevSrc={images[(photoIndex + images?.length - 1) % images?.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((prev) => (prev + images?.length - 1) % images?.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((prev) => (prev + 1) % images?.length)
          }
          style={{ zIndex: 99999 }}
        />
      )}
    </React.Fragment>
  )
}

export default function AppointmentTable({ appointments }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow sx={{ fontWeight: 500 }}>
            <TableCell></TableCell>
            <TableCell>Title</TableCell>
            <TableCell align="left">REG</TableCell>
            <TableCell align="left">Seller Name</TableCell>
            <TableCell align="left">Seller Phone</TableCell>
            <TableCell align="center">Date & Time</TableCell>
            <TableCell align="center">Address</TableCell>
            <TableCell align="center">Agreed Price</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {appointments &&
            appointments?.map((event) => (
              <Row key={event?._id} event={event} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
