import {
  Alert,
  Backdrop,
  CircularProgress,
  MenuItem,
  Select,
  Stack,
} from "@mui/material"
import axios from "axios"
import React from "react"
import { URL } from "../../config/apis"
import useAuth from "../../context/Auth"
import useData from "../../context/Data"

export default function SelectDealership() {
  const { user } = useAuth()
  const { setErr, dealerships } = useData()
  const [loading, setLoading] = React.useState(false)

  const setDealership = async (id) => {
    if (!user?.supperAdmin) {
      setErr("Action Not allowed")
      return setTimeout(() => {
        setErr(null)
      }, 2000)
    }
    setErr(null)
    setLoading(true)
    try {
      const res = await axios.patch(
        `${URL}/users/select?type=full`,
        { sid: id },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      sessionStorage.removeItem("leads-filter")
      setTimeout(() => {
        window.location.reload(true)
      }, 1000)
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
      setLoading(false)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  return (
    <>
      <Stack
        direction={"row"}
        alignItems="center"
        sx={{ px: { xs: 2, md: 5 } }}
        py={{ xs: 1, md: 0.5 }}
        gap={1}
        bgcolor="#4caf50"
        flexWrap={"wrap"}
      >
        <div>Logged in as:</div>
        <Select
          //   size="small"
          labelId="select-dealership"
          id="select-dealership"
          value={user?.selected?._id}
          label="Selected"
          onChange={({ target }) => setDealership(target.value)}
          sx={{
            color: "white",
            fontSize: 14,
            px: 2,
            py: 0.5,
            borderColor: "white",
            fontWeight: "700",
          }}
          variant="standard"
          className="dselect"
        >
          {dealerships &&
            dealerships?.map((d, i) => (
              <MenuItem value={d?._id} key={d?._id + i}>
                {d?.dname}
              </MenuItem>
            ))}
        </Select>
      </Stack>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
