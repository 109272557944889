import * as React from "react"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { styled, useTheme } from "@mui/material"
import { appName, navItems } from "../../constants/constants"
import { GlobalLayoutContext } from "../layouts/MainLayout"
import { NavLink } from "react-router-dom"

const drawerWidth = 240
// const navItems = ["Home", "About", "Contact"]
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}))

export default function SideBarResponssive({ container }) {
  const theme = useTheme()
  const { handleDrawerOpen, open, handleDrawerClose } =
    React.useContext(GlobalLayoutContext)
  return (
    <Box component="nav">
      <Drawer
        container={container}
        variant="temporary"
        open={open}
        onClose={handleDrawerOpen}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        <DrawerHeader>
          <img
            src="/theautofinder-main.svg"
            alt={`The Auto Finder`}
            style={{
              width: "100%",
              maxWidth: "180px",
              paddingLeft: 2,
              paddingRight: 2,
            }}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box sx={{ textAlign: "center" }}>
          <List
            onClick={handleDrawerClose}
            alignItems="center"
            sx={{ width: "100%" }}
          >
            {/* {navItems.map((item) => (
              <ListItem key={item} disablePadding>
                <ListItemButton sx={{ textAlign: "center" }}>
                  <ListItemText primary={item} />
                </ListItemButton>
              </ListItem>
            ))} */}
            {navItems.map((item) => (
              <ListItem key={item}>
                <NavLink
                  style={({ isActive }) => ({
                    textDecoration: "none",
                    color: isActive ? "#0059ff" : "#2c2e32",
                    fontWeight: 500,
                    textAlign: "center",
                  })}
                  key={item.href}
                  to={item.href}
                >
                  {item.label}
                </NavLink>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  )
}
