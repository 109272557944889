import { Navigate, Outlet, useLocation } from "react-router-dom"
import useAuth from "../../context/Auth"
import MainLayout from "./MainLayout"

export const PrivateRoutes = () => {
  const { user } = useAuth()
  const location = useLocation()

  return user ? (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ) : (
    <Navigate to="/login" state={location} />
  )
}
