import { Box, Button, Stack, Typography } from "@mui/material"
import React from "react"
import moment from "moment"
import axios from "axios"
import { URL } from "../../config/apis"
import useData from "../../context/Data"
import { useMemo } from "react"

export default function AcceptedActions({
  lead,
  setLeads,
  setProgress,
  getLeads,
}) {
  const { loading, setLoading, setShow, setErr, disabled } = useData()

  const lost = useMemo(() => {
    if (lead?.isLost) return true
    if (lead?.lead?.isTrash) return true
    return false
  }, [lead?.isLost, lead?.isTrash])

  const onUnaccept = async () => {
    setLoading(true)
    setProgress(true)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${lead?._id}/unaccept`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLeads((prev) => [...prev?.filter((l) => l?._id !== lead?._id)])
        setLoading(false)
        setProgress(false)
        setShow(true)
        getLeads()
        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    } catch (error) {
      setLoading(false)
      setProgress(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 5000)
    }
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", md: "16% !important" },
          px: { xs: 10, md: 2 },
          py: { xs: 2, md: 1 },
          justifyContent: "center",
          alignItems: "center",flexShrink:0
        }}
      >
        <Stack alignItems={"center"} spacing={1}>
          <Button
            onClick={onUnaccept}
            disabled={loading || disabled}
            variant="contained"
            color="error"
          >
            Unaccept
          </Button>

          <Typography color={"green"} fontWeight={600} variant="body1">
            Accepted
          </Typography>
          <Typography variant="body2">
            {moment(lead?.acceptedAt).format("DD/MM/YYYY")}
          </Typography>
          <Typography
            variant="body2"
            color={lost ? "red" : "inherit"}
            textAlign={"center"}
          >
            {lost ? "LOST" : "Waiting to Schedule Appointment with Seller"}
          </Typography>
        </Stack>
      </Box>
    </>
  )
}
