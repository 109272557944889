import { useState } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import "./App.css"
import MainLayout from "./components/layouts/MainLayout"
import { PrivateRoutes } from "./components/layouts/PrivateRoutes"
import useAuth from "./context/Auth"
import NotFound from "./NotFound"
import AcceptedPage from "./pages/AcceptedPage"
import AppointmentsPage from "./pages/AppointmentsPage"
import BidsPage from "./pages/BidsPage"
import CountersPage from "./pages/CountersPage"
import Dealerships from "./pages/Dealerships"
import DealsPage from "./pages/DealsPage"
import DeniedPage from "./pages/DeniedPage"
import Login from "./pages/Login"
import MainPage from "./pages/MainPage"
import ProfilePage from "./pages/ProfilePage"
import SearchPage from "./pages/SearchPage"
import InterestedPage from "./pages/InterestedPage"

function App() {
  const { user, selected } = useAuth()

  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<Navigate to={"/cars"} />} />
        {/* <Route path="/dealerships" element={<Dealerships />} /> */}
        <Route path="/cars" element={<MainPage />} />
        <Route path="/interested" element={<InterestedPage />} />
        <Route path="/counters" element={<CountersPage />} />
        <Route path="/my-bids" element={<BidsPage />} />
        <Route path="/accepted" element={<AcceptedPage />} />
        <Route path="/appointments" element={<AppointmentsPage />} />
        <Route path="/declined" element={<DeniedPage />} />
        <Route path="/deals" element={<DealsPage />} />
        <Route path="/search" element={<SearchPage />} />

        <Route path="/profile" element={<ProfilePage />} />

        <Route path="/*" element={<NotFound />} />
      </Route>
      <Route
        path="/login"
        element={!user ? <Login /> : <Navigate to={"/"} />}
      />
    </Routes>
  )
}

export default App
