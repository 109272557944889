import React from "react"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"

export default function LineGrid({
  lable,
  value,
  logo = false,
  color = "#5a6164ed",
}) {
  return (
    <Stack alignItems={"start"}>
      <Typography
        fontWeight={900}
        component="legend"
        display="flex"
        alignItems={"center"}
        fontSize={{ xs: "13px", md: "15px" }}
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {logo && (
          <img src="/aticon.png" height={20} style={{ marginRight: 5 }} />
        )}
        {lable}
      </Typography>
      <Typography
        fontSize="15px"
        fontWeight={600}
        component="legend"
        sx={{ color: color }}
      >
        {value}
      </Typography>
    </Stack>
  )
}
