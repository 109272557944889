import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { formatDate, formatPrice } from "../../helppers/formatters"
import { IconButton, Typography } from "@mui/material"
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined"
import Lightbox from "react-18-image-lightbox"

function Row({ deal }) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [photoIndex, setPhotoIndex] = React.useState(0)

  const images = React.useMemo(() => {
    if (!deal) return []
    if (!deal?.f_image) return deal?.images?.map((x) => x?.url)
    return [deal?.f_image?.url, ...deal?.images?.map((x) => x?.url)]
  }, [deal])
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell sx={{ px: 0 }} align="center" component="th" scope="row">
          <IconButton
            onClick={() => setIsOpen(true)}
            aria-label="images"
            component="button"
          >
            <PermMediaOutlinedIcon />
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="body1" fontWeight={600}>
            {deal?.title}
          </Typography>
        </TableCell>
        <TableCell align="left">{deal?.vrm}</TableCell>
        <TableCell align="left">{deal?.vin}</TableCell>
        <TableCell align="left">{deal?.mileage?.toLocaleString()}</TableCell>
        <TableCell align="center">{formatDate(deal?.purchaseAt)}</TableCell>
        <TableCell align="center">{formatPrice(deal.purchasePrice)}</TableCell>
      </TableRow>
      {isOpen && images?.length > 0 && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images?.length]}
          prevSrc={images[(photoIndex + images?.length - 1) % images?.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (prev) => (prev + images?.length - 1) % images?.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((prev) => (prev + 1) % images?.length)
          }
          style={{ zIndex: 99999 }}
        />
      )}
    </React.Fragment>
  )
}

export default function DealsTable({ deals }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow sx={{ fontWeight: 500 }}>
            <TableCell></TableCell>
            <TableCell>Title</TableCell>
            <TableCell align="left">VRM</TableCell>
            <TableCell align="left">VIN</TableCell>
            <TableCell align="left">Mileage</TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Purchase Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deals && deals?.map((deal) => <Row key={deal?._id} deal={deal} />)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
