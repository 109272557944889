import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import React from "react"

export default function SelectMenu({
  children,
  label,
  name,
  value,
  handleChange,
  options = [],
  ...props
}) {
  return (
    <Box {...props}>
      <FormControl sx={{bgcolor: 'white'}} size="small" fullWidth>
        <InputLabel size="small" id={label}>
          {label}
        </InputLabel>
        <Select
          labelId={label}
          id={label}
          value={value}
          label={label}
          onChange={(e) => handleChange(e.target.value, name)}
          size="small"
          MenuProps={{ sx: { maxHeight: 500 } }}
        >
          {children}
        </Select>
      </FormControl>
    </Box>
  )
}
