import * as React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Stack from "@mui/material/Stack"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import Snackbar from "@mui/material/Snackbar"
import LeadCard from "../components/leadCard"

import axios from "axios"
import { URL } from "../config/apis"
import { useEffect } from "react"
import LoadingPage from "../components/global/LoadingPage"
import EmptyState from "../components/global/EmptyState"
import { CircularProgress, Divider, Typography } from "@mui/material"
import LeadFilters from "../components/filters/LeadFilters"

export default function MainPage() {
  const cash = JSON.parse(sessionStorage.getItem("leads-filter"))
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(true)
  const [leads, setLeads] = React.useState([])
  const [err, setErr] = React.useState(null)
  const [count, setCount] = React.useState(null)

  const [make, setMake] = React.useState(cash?.make || "All")
  const [sort, setSort] = React.useState(
    cash?.sort || JSON.stringify({ sort: "sentAt", val: -1 })
  )
  const [margin, setMargin] = React.useState(cash?.margin || [0, 9000])

  const getLeads = async () => {
    setErr(null)
    setIsLoading(true)
    try {
      const res = await axios.get(
        `${URL}/leads?make=${make}&margin=${margin}&sort=${encodeURIComponent(
          sort
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      setLeads(res.data.leads)
      setCount(res.data.count)
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
    }
    setLoading(false)
    setIsLoading(false)
  }

  useEffect(() => {
    getLeads()

    sessionStorage.setItem("leads-filter", JSON.stringify({ sort }))
  }, [sort])

  const applayFilters = () => {
    getLeads()
    sessionStorage.setItem("leads-filter", JSON.stringify({ make, margin }))
  }

  if (loading) {
    return <LoadingPage />
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth="lg"
        sx={{
          flex: 1,
          height: "100%",
          flexDirection: "column",
          display: "flex",
          py: 2,
        }}
      >
        <Stack sx={{ width: "100%" }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={2}
            flexWrap={"wrap"}
            position={"relative"}
            pb={{xs: 5, md:0}}
          >
            <LeadFilters
              make={make}
              setMake={setMake}
              margin={margin}
              setMargin={setMargin}
              getLeads={applayFilters}
              sort={sort}
              setSort={setSort}
            />
            {count !== null && count > 0 && (
              <Typography
                sx={{
                  position: { xs: "absolute", md: "relative" },
                  bottom: 3,
                  right: 5,
                }}
                fontWeight={"600"}
              >
                {leads?.length}/{count}
              </Typography>
            )}
          </Stack>
          <Divider flexItem />
        </Stack>
        <Stack
          sx={{ width: "100%", mt: 1, position: "relative", minHeight: 300 }}
          spacing={2}
        >
          {err ? (
            <>{err}</>
          ) : leads?.length === 0 ? (
            <EmptyState text="NEW CARS COMING SOON!" />
          ) : (
            leads?.map((lead, i) => (
              <LeadCard
                setOpen={setOpen}
                lead={lead}
                key={lead?._id + i}
                setLeads={setLeads}
                getLeads={getLeads}
              />
            ))
          )}
          {isLoading && (
            <Stack
              alignItems={"center"}
              position={"absolute"}
              zIndex={10}
              sx={{ inset: 0, bgcolor: "#f8f8f869" }}
              py={10}
            >
              <Paper
                component={Typography}
                sx={{ px: 5, py: 2, bgcolor: "white", alignItems: "center" }}
              >
                <CircularProgress sx={{ mr: 1 }} size={20} /> Loading...
              </Paper>
            </Stack>
          )}
        </Stack>
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        // onClose={handleClose}
        message="Copied"
      />
    </React.Fragment>
  )
}
