import React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Stack from "@mui/material/Stack"
import CircularProgress from "@mui/material/CircularProgress"

import Container from "@mui/material/Container"

export default function LoadingBox() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth="lg"
        sx={{
          width: "100%",
          //  flexDirection: "column",
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack
          sx={{ width: "100%", height: "100%" }}
          alignItems="center"
          justifyContent={"center"}
          spacing={2}
        >
          <CircularProgress />
        </Stack>
      </Container>
    </React.Fragment>
  )
}
