import { Alert, Snackbar } from "@mui/material"
import React from "react"

export default function FeedBackBox({ loading, show, err }) {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={loading}
        message="Please wait ..."
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        open={show}
        sx={{ minWidth: "200px" }}
      >
        <Alert
          color="success"
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Success
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        open={err !== null}
        sx={{ minWidth: "200px", maxWidth: "400px" }}
        severity="error"
      >
        <Alert
          color="error"
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {err}
        </Alert>
      </Snackbar>
    </>
  )
}
