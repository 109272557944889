import React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Stack from "@mui/material/Stack"
import Container from "@mui/material/Container"
import ProfileCard from "../components/profile/ProfileCard"

export default function ProfilePage() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth="lg"
        sx={{
          width: "100%",
          flexDirection: "column",
          display: "flex",
          minHeight: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          flex: 1,
          color: "#747373",
        }}
      >
        <Stack
          sx={{ width: "100%", height: "100%" }}
          alignItems="center"
          justifyContent={"center"}
          spacing={2}
        >
          <ProfileCard />
        </Stack>
      </Container>
    </React.Fragment>
  )
}
