import { Container, Divider, Snackbar, Stack } from "@mui/material"
import React from "react"
import LeadCard from "../leadCard"

export default function SectionBox({ type, data, getResults, setLeads, title }) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          flex: 1,
          height: "100%",
          flexDirection: "column",
          display: "flex",
          py: 2,
        }}
      >
        <Divider className="dp-divider">{title}</Divider>
        <Stack sx={{ width: "100%", my:2 }} spacing={2}>
          {data?.map((lead, i) => (
            <LeadCard
              setOpen={setOpen}
              lead={lead}
              key={lead?._id + i}
              setLeads={setLeads}
              type={type}
              getLeads={getResults}
            />
          ))}
        </Stack>
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        // onClose={handleClose}
        message="Copied"
      />
    </>
  )
}
