import * as React from "react"
import PropTypes from "prop-types"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import InputBase from "@mui/material/InputBase"
import Avatar from "@mui/material/Avatar"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MenuIcon from "@mui/icons-material/Menu"
import Toolbar from "@mui/material/Toolbar"
import Stack from "@mui/material/Stack"
import { alpha, styled } from "@mui/material/styles"
import SearchIcon from "@mui/icons-material/Search"
import SideBarResponssive from "./SideBarResponssive"
import { appName } from "../../constants/constants"
import { GlobalLayoutContext } from "../layouts/MainLayout"
import Tooltip from "@mui/material/Tooltip"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import useAuth from "../../context/Auth"
import SelectDealership from "./SelectDealership"

const settings = [
  { label: "Profile", href: "/profile" },
  // { label: "Dealerships", href: "/dealerships" },
  { label: "Logout", href: "#logout", isLogOut: true },
]

const navItems = [
  { label: "Cars Available", href: "/cars" },
  { label: "Interested", href: "/interested" },
  { label: "My Bids", href: "/my-bids" },
  { label: "Counters", href: "/counters" },
  { label: "Accepted", href: "/accepted" },
  // { label: "Appointments", href: "/appointments" },
  { label: "Deals", href: "/deals" },
  { label: "Declined", href: "/declined" },
]

// const navItems = ["Home", "Accepted", "Countered", "Appointments", "Denied"]

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}))

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}))

function ResponssiveAppBar({ window }) {
  const { handleDrawerOpen } = React.useContext(GlobalLayoutContext)
  const { user, logoutUser } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()

  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const container =
    window !== undefined ? () => window().document.body : undefined

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <>
      <AppBar sx={{ zIndex: "999 !important" }} component="nav">
        {user?.supperAdmin && <SelectDealership />}
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Stack
            direction={"row"}
            sx={{
              flexGrow: 1,
              display: "flex",
              height: { xs: "100%", md: "auto" },
            }}
            alignItems="center"
          >
            <img
              src="/theautofinder-white.svg"
              alt={`The Auto Finder`}
              style={{ width: "100%", maxWidth: "180px" }}
            />
            {/* <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            >
              {appName}
            </Typography> */}
          </Stack>
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search> */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              gap: 3,
              paddingLeft: 5,
            }}
          >
            {navItems.map((item) => (
              <NavLink
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color:
                    item.label === "Interested"
                      ? "#ffa000"
                      : isActive
                      ? "#b6dbff"
                      : "#fff",
                  fontWeight: 500,
                })}
                key={item.href}
                to={item.href}
              >
                {item.label}
              </NavLink>
            ))}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "flex" }, alignItems: "center", gap: 2 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => navigate("/search", { state: location })}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    sx={{ width: "30px", height: "30px" }}
                    alt={user?.fname}
                    src={user?.avatar?.url}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting?.href} onClick={handleCloseUserMenu}>
                    {setting.isLogOut ? (
                      <NavLink
                        style={({ isActive }) => ({
                          textDecoration: "none",
                          color: setting.isLogOut
                            ? "#b30202"
                            : isActive
                            ? "#1976d2"
                            : "#423e3e",
                          fontWeight: 500,
                        })}
                        to="#logout"
                        onClick={logoutUser}
                      >
                        {setting.label}
                      </NavLink>
                    ) : (
                      <NavLink
                        style={({ isActive }) => ({
                          textDecoration: "none",
                          color: setting.isLogOut
                            ? "#b30202"
                            : isActive
                            ? "#1976d2"
                            : "#423e3e",
                          fontWeight: 500,
                        })}
                        to={setting.href}
                      >
                        {setting.label}
                      </NavLink>
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <SideBarResponssive container={container} />
    </>
  )
}

ResponssiveAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

export default ResponssiveAppBar
