import * as React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Stack from "@mui/material/Stack"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import Snackbar from "@mui/material/Snackbar"
import LeadCard from "../components/leadCard"

import axios from "axios"
import { URL } from "../config/apis"
import { useEffect } from "react"
import LoadingPage from "../components/global/LoadingPage"
import EmptyState from "../components/global/EmptyState"
import { Divider } from "@mui/material"

export default function AcceptedPage() {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(true)
  const [leads, setLeads] = React.useState([])
  // const [losts, setLosts] = React.useState([])
  const [err, setErr] = React.useState(null)

  const getLeads = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get(`${URL}/leads/accepted`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setLeads(res.data.leads)
      // setLosts(res.data.losts)
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
    }
    setLoading(false)
    setIsLoading(false)
  }

  useEffect(() => {
    getLeads()
  }, [])

  if (loading) {
    return <LoadingPage />
  }

  // if (!loading && !err && leads?.length === 0) {
  //   return (
  //     <EmptyState
  //       text="NO DEALS ACCEPTED YET!"
  //       extra="These cars won't buy themselves..."
  //       btn
  //     />
  //   )
  // }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth="lg"
        sx={{
          flex: 1,
          height: "100%",
          flexDirection: "column",
          display: "flex",
          py: 5,
        }}
      >
        <Stack sx={{ width: "100%", height: "100%" }} spacing={2}>
          {!loading && !err && leads?.length === 0 ? (
            <EmptyState
              text="NO DEALS ACCEPTED YET!"
              extra="These cars won't buy themselves..."
              btn
            />
          ) : (
            <>
              {leads?.map((lead, i) => (
                <LeadCard
                  setOpen={setOpen}
                  lead={lead}
                  key={lead?._id + i}
                  setLeads={setLeads}
                  type="accepted"
                  getLeads={getLeads}
                />
              ))}
            </>
          )}
          {/* {losts?.length > 0 && <Divider />}
          {losts?.map((lead, i) => (
            <LeadCard
              setOpen={setOpen}
              lead={lead}
              key={lead?._id + i}
              setLeads={setLeads}
              type="accepted"
              getLeads={getLeads}
            />
          ))} */}
        </Stack>
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        // onClose={handleClose}
        message="Copied"
      />
    </React.Fragment>
  )
}
