import * as React from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import useAuth from "../../context/Auth"
import { Avatar, Button, Divider, Stack } from "@mui/material"

const ProfileBox = ({ user }) => {
  return (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5" component="div">
          {user?.fname}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {user?.selected?.dname}
        </Typography>
        <Typography variant="body2"> {user?.email}</Typography>
        <Typography variant="body2"> {user?.phone}</Typography>
      </CardContent>
    </React.Fragment>
  )
}

export default function ProfileCard() {
  const { user } = useAuth()

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          display: "flex",
          height: "220px",
          bgcolor: "white",
          flex: 1,
          pb: 2,
        }}
      >
        <div
          className="coverp"
          style={{ backgroundImage: `url("/theautofinder-white.svg")` }}
        ></div>
        <Stack
          width={"100%"}
          px={2}
          spacing={2}
          direction={"row"}
          zIndex={1}
          alignItems={"flex-end"}
        >
          <Avatar sx={{ height: 90, width: 90 }} />
          <Stack
            width={"100%"}
            direction={"row"}
            spacing={3}
            justifyContent="space-between"
            alignItems={"end"}
          >
            <Stack>
              <Typography pt={5} fontWeight={600} variant="h5">
                {user?.fname}
              </Typography>
              <Typography>{user?.selected?.dname}</Typography>
            </Stack>
            <Stack>{/* <Button variant="contained">Edit</Button> */}</Stack>
          </Stack>
        </Stack>
      </Box>
      <Divider />
    </Box>
  )
}
