export const makesWithLogos = [
  { name: "Acura", logo: "/logos/acura.png" },
  { name: "Alfa Romeo", logo: "/logos/alfa_romeo.png" },
  { name: "Aston Martin", logo: "/logos/aston_martin.png" },
  { name: "Audi", logo: "/logos/audi.png" },
  { name: "Bentley", logo: "/logos/bentley.png" },
  { name: "BMW", logo: "/logos/bmw.png" },
  { name: "Buick", logo: "/logos/buick.png" },
  { name: "Cadillac", logo: "/logos/cadillac.png" },
  { name: "Chevrolet", logo: "/logos/chevrolet.png" },
  { name: "Chrysler", logo: "/logos/chrysler.png" },
  { name: "Dodge", logo: "/logos/dodge.png" },
  { name: "Ferrari", logo: "/logos/ferrari.png" },
  { name: "FIAT", logo: "/logos/fiat.png" },
  { name: "Ford", logo: "/logos/ford.png" },
  { name: "Genesis", logo: "/logos/genesis.png" },
  { name: "GMC", logo: "/logos/gmc.png" },
  { name: "Honda", logo: "/logos/honda.png" },
  { name: "HUMMER", logo: "/logos/hummer.png" },
  { name: "Hyundai", logo: "/logos/hyundai.png" },
  { name: "INFINITI", logo: "/logos/infiniti.png" },
  { name: "Jaguar", logo: "/logos/jaguar.png" },
  { name: "Jeep", logo: "/logos/jeep.png" },
  { name: "Kia", logo: "/logos/kia.png" },
  { name: "Lamborghini", logo: "/logos/lamborghini.png" },
  { name: "Land Rover", logo: "/logos/land_rover.png" },
  { name: "Lexus", logo: "/logos/lexus.png" },
  { name: "Lincoln", logo: "/logos/lincoln.png" },
  { name: "Lotus", logo: "/logos/lotus.png" },
  { name: "Maserati", logo: "/logos/maserati.png" },
  { name: "Maybach", logo: "/logos/maybach.png" },
  { name: "MAZDA", logo: "/logos/mazda.png" },
  { name: "McLaren", logo: "/logos/mclaren.png" },
  { name: "Mercedes-Benz", logo: "/logos/mercedes_benz.png" },
  { name: "Mercury", logo: "/logos/mercury.png" },
  { name: "MINI", logo: "/logos/mini.png" },
  { name: "Mitsubishi", logo: "/logos/mitsubishi.png" },
  { name: "Nissan", logo: "/logos/nissan.png" },
  { name: "Pontiac", logo: "/logos/pontiac.png" },
  { name: "Porsche", logo: "/logos/porsche.png" },
  { name: "Ram", logo: "/logos/ram.png" },
  { name: "Rolls-Royce", logo: "/logos/rolls_royce.png" },
  { name: "Saturn", logo: "/logos/saturn.png" },
  { name: "Scion", logo: "/logos/scion.png" },
  { name: "SRT", logo: "/logos/srt.png" },
  { name: "Subaru", logo: "/logos/subaru.png" },
  { name: "Suzuki", logo: "/logos/suzuki.png" },
  { name: "Tesla", logo: "/logos/tesla.png" },
  { name: "Toyota", logo: "/logos/toyota.png" },
  { name: "Volkswagen", logo: "/logos/volkswagen.png" },
  { name: "Volvo", logo: "/logos/volvo.png" },
]
