import { Box, Button, Stack, Typography } from "@mui/material"
import axios from "axios"
import React from "react"
import { useState } from "react"
import { URL } from "../../config/apis"
import useData from "../../context/Data"
import { formatPrice } from "../../helppers/formatters"
import PricesInput from "../inputs/PricesInput"
import HistoryIcon from "@mui/icons-material/History"
import moment from "moment"

export default function CounterActions({
  setLeads,
  lead,
  setProgress,
  getLeads,
}) {
  const { loading, setLoading, setShow, setErr, disabled } = useData()
  const [open, setOpen] = useState(false)
  const [counter, setCounter] = useState()

  const onAccept = async () => {
    setLoading(true)
    setProgress(true)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${lead?._id}/accept-counter`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLeads((prev) => [...prev?.filter((l) => l?._id !== lead?._id)])
        setLoading(false)
        setProgress(false)
        setShow(true)
        getLeads()
        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    } catch (error) {
      setLoading(false)
      setProgress(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 5000)
    }
  }

  const onCounter = async () => {
    if (!counter || counter === 0) {
      setErr("Please add a offer amount")
      return setTimeout(() => {
        setErr(null)
      }, 2000)
    }
    setLoading(true)
    setProgress(true)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${lead?._id}/counter`,
        { counter },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLeads((prev) => [...prev?.filter((l) => l?._id !== lead?._id)])
        setLoading(false)
        setProgress(false)
        setShow(true)
        getLeads()
        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    } catch (error) {
      setLoading(false)
      setProgress(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 5000)
    }
  }

  const onDeniey = async () => {
    setLoading(true)
    setProgress(true)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${lead?._id}/deny`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLeads((prev) => [...prev?.filter((l) => l?._id !== lead?._id)])
        setLoading(false)
        setProgress(false)
        setShow(true)
        getLeads()
        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    } catch (error) {
      setLoading(false)
      setProgress(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 5000)
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", md: "15% !important" },
          px: { xs: 2, md: 2 },
          py: { xs: 2, md: 1 },
          justifyContent: "center",
          alignItems: "center",
          flexShrink: 0,
        }}
      >
        {open ? (
          <Stack
            width={"100%"}
            direction={{ xs: "row", md: "column" }}
            spacing={2}
          >
            <PricesInput
              lable="New Bid"
              onValueChange={setCounter}
              value={counter}
              //  sx={{ maxWidth: "140px" }}
              disabled={loading || disabled}
            />
            <Button
              onClick={onCounter}
              disabled={loading || disabled}
              variant="outlined"
            >
              Send
            </Button>
            <Button
              onClick={() => {
                setOpen(false)
              }}
              disabled={loading}
              variant="text"
              color="error"
            >
              Cancel
            </Button>
          </Stack>
        ) : (
          <Stack spacing={1}>
            {lead?.counter && (
              <Stack alignItems={'center'}>
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"center"}
                  spacing={1}
                  sx={{
                    color:
                      lead?.counter?.status === "Rejected"
                        ? "#e00909"
                        : "inherit",
                  }}
                >
                  <HistoryIcon sx={{ height: "20px", width: "20px" }} />
                  <Typography
                    fontWeight={500}
                    textAlign={"center"}
                    variant="body2"
                    fontSize={14}
                  >
                    {formatPrice(lead?.counter?.amount)} {lead?.counter?.status}
                  </Typography>
                </Stack>
                <small>{moment(lead?.counter?.createdAt).format('DD/MM/YY @ h:m A')}</small>
              </Stack>
            )}
            <Stack
              direction={{ xs: "row", md: "column" }}
              flexWrap="wrap"
              spacing={2}
            >
              <Button
                sx={{ fontSize: { xs: "0.755rem", lg: "0.875rem" } }}
                onClick={onAccept}
                disabled={loading || disabled}
                variant="contained"
              >
                Accept{" "}
                {lead?.lastCounter?.amount
                  ? formatPrice(lead?.lastCounter?.amount)
                  : formatPrice(lead?.prePrice)}
              </Button>
              <Button
                onClick={() => {
                  setOpen(true)
                }}
                disabled={loading || disabled}
                variant="outlined"
                sx={{ fontSize: { xs: "0.755rem", lg: "0.875rem" } }}
              >
                New Bid
              </Button>
              <Button
                onClick={onDeniey}
                disabled={loading || disabled}
                variant="text"
                color="error"
                sx={{ fontSize: { xs: "0.755rem", lg: "0.875rem" } }}
              >
                Decline
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
    </>
  )
}
