import * as React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Container from "@mui/material/Container"
import axios from "axios"
import { URL } from "../config/apis"
import { useEffect } from "react"
import LoadingPage from "../components/global/LoadingPage"
import DealsTable from "../components/deals/DealsTable"
import EmptyState from "../components/global/EmptyState"
import DealsPageFilter from "../components/global/filters/DealsPageFilter"
import { currentMonth, months } from "../constants/months"
import { CircularProgress, Stack } from "@mui/material"

export default function DealsPage() {
  const [loading, setLoading] = React.useState(true)
  const [fetching, setFetching] = React.useState(false)
  const [deals, setDeals] = React.useState([])
  const [err, setErr] = React.useState(null)
  const [month, setMonth] = React.useState(months[currentMonth])

  const getLeads = async () => {
    setErr(null)
    setFetching(true)
    try {
      const res = await axios.get(`${URL}/leads/deals?month=${month}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setDeals(res.data)
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
    }
    setLoading(false)
    setFetching(false)
  }

  useEffect(() => {
    getLeads()
  }, [month])

  if (loading) {
    return <LoadingPage />
  }

  if (err) {
    return <>{err}</>
  }

  // if (!loading && !err && deals?.length === 0) {
  //   return <EmptyState text="No Deals Done Yet" />
  // }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth="100%"
        sx={{
          flex: 1,
          height: "100%",
          flexDirection: "column",
          display: "flex",
          width: "100%",
          position: "relative",
        }}
      >
        {fetching && (
          <Stack
            sx={{
              inset: 0,
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              bgcolor: "#ffffff78",
              minHeight: 300,
            }}
          >
            <CircularProgress />
          </Stack>
        )}
        {/* //filters */}
        <DealsPageFilter month={month} setMonth={setMonth} />
        {deals?.length === 0 ? (
          <EmptyState text="No Deals Done Yet" />
        ) : (
          <DealsTable deals={deals} />
        )}
      </Container>
    </React.Fragment>
  )
}
