import { MenuItem } from "@mui/material"
import React from "react"
import { months } from "../../constants/months"
import SelectMenu from "./SelectMenu"

export default function SelectMonth({ value, setValue }) {
  return (
    <SelectMenu
      handleChange={(v) => setValue(v)}
      label={"Months"}
      value={value}
      options={months}
      sx={{ minWidth: 150 }}
    >
      {months &&
        months?.map((m, i) => (
          <MenuItem key={m + i} value={m}>
            {m}
          </MenuItem>
        ))}
    </SelectMenu>
  )
}
