import React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Stack from "@mui/material/Stack"
import { TbFilesOff } from "react-icons/tb"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"

export default function EmptyState({
  icon = <TbFilesOff fontSize={50} />,
  text = "No Leads Found",
  extra = null,
  btn = false,
}) {
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth="lg"
        sx={{
          width: "100%",
          flexDirection: "column",
          display: "flex",
          minHeight: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          flex: 1,
          color: "#747373",
        }}
      >
        <Stack
          sx={{ width: "100%", height: "100%" }}
          alignItems="center"
          justifyContent={"center"}
          spacing={2}
        >
          {icon}
          <Typography variant="h6">{text}</Typography>
          {extra && (
            <Stack>
              <Typography variant="subtitle1">{extra}</Typography>
            </Stack>
          )}
          {btn && (
            <Button onClick={() => navigate("/cars")} variant="contained">
              GO!
            </Button>
          )}
        </Stack>
      </Container>
    </React.Fragment>
  )
}
