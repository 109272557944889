import React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Stack from "@mui/material/Stack"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import Skeleton from "@mui/material/Skeleton"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"

const CardSkeleton = () => {
  return (
    <Card sx={{ display: "flex" }}>
      <Skeleton
        sx={{ width: "30%", maxWidth: "300px", flex: "none", height: "230px" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: 2,
          justifyContent: "center",
        }}
      >
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Stack spacing={2}>
            <Stack
              spacing={5}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontWeight={600}
                sx={{ flexGrow: 1, maxWidth: "50%" }}
                component="div"
                variant="h5"
              >
                <Skeleton />
              </Typography>
              <Typography
                sx={{ flex: "none", width: "100px" }}
                component="div"
                variant="h5"
              >
                <Skeleton />
              </Typography>
            </Stack>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <Skeleton width={100} />
                <Skeleton width={100} />
                <Skeleton width={100} />
                <Skeleton width={100} />
              </Stack>
              
              <Stack direction="row" spacing={2}>
                <Skeleton width={100} />
                <Skeleton width={100} />
                <Skeleton width={100} />
                <Skeleton width={100} />
              </Stack>
              
              <Stack direction="row" spacing={2}>
                <Skeleton width={100} />
                <Skeleton width={100} />
                <Skeleton width={100} />
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Box>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Stack
        spacing={2}
        width={"16%"}
        alignItems="center"
        justifyContent={"center"}
        sx={{ px: 5 }}
      >
        <Skeleton width={"100%"} height="40px" />
        <Skeleton width={"100%"} height="40px" />
        <Skeleton width={"100%"} height="40px" />
      </Stack>
    </Card>
  )
}

export default function LoadingPage() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth="lg"
        sx={{
          flex: 1,
          height: "100%",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <Paper
          sx={{
            bgcolor: "white",
            flex: 1,
            px: 5,
            py: 2,
          }}
        >
          <Stack sx={{ width: "100%" }} spacing={2}>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </Stack>
        </Paper>
      </Container>
    </React.Fragment>
  )
}
