import { Stack } from "@mui/material"
import React from "react"
import SelectMonth from "../../inputs/SelectMonth"

export default function DealsPageFilter({ month, setMonth }) {
  return (
    <Stack direction={"row"} spacing={2} pt={1} pb={2} justifyContent='end'>
      <SelectMonth value={month} setValue={setMonth} />
    </Stack>
  )
}
