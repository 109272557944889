import axios from "axios"
import React from "react"
import { useState } from "react"
import { useLocation } from "react-router-dom"
import { URL } from "../config/apis"
import useAuth from "../context/Auth"
import CssBaseline from "@mui/material/CssBaseline"
import Stack from "@mui/material/Stack"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

import Container from "@mui/material/Container"
import { Alert, Button, Paper, Typography } from "@mui/material"

import IconButton from "@mui/material/IconButton"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import InputAdornment from "@mui/material/InputAdornment"

const getHtmlPre = (html) => {
  const parser = new DOMParser()
  const document = parser.parseFromString(html, "text/html")
  const el = document.getElementsByTagName("pre")[0]
  return el?.innerText?.split("at ")[0]
}

export default function LoginPage() {
  const { setUser, setLoading } = useAuth()
  const { state } = useLocation()

  const [err, setErr] = useState(null)
  const [isLoding, setIsLoading] = useState(false)
  const [showPassword, setshowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setshowPassword((prev) => !prev)
  }

  const login = async (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form.entries())
    setIsLoading(true)
    try {
      const res = await axios.post(`${URL}/auth/login`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setIsLoading(false)
      setLoading(true)

      setTimeout(() => {
        if (state?.pathname) {
          window.location.replace(state?.pathname)
        } else {
          window.location.replace("/")
        }
      }, 1000)
    } catch (error) {
      console.log(error)
      setErr(error.response?.data?.message || error.message)
      setIsLoading(false)
    }
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth="xs"
        sx={{
          width: "100%",
          //  flexDirection: "column",
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper variant="outlined" sx={{ width: "100%" }}>
          <Stack
            component="form"
            sx={{
              width: "100%",
              height: "100%",
              bgcolor: "white",
              py: 5,
              px: 5,
            }}
            alignItems="center"
            justifyContent={"center"}
            spacing={2}
            noValidate
            autoComplete="off"
            onSubmit={login}
          >
            {err && (
              <Alert severity="error">
                <div dangerouslySetInnerHTML={{ __html: err }} />
              </Alert>
            )}
            <Stack
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
              sx={{ width: "100%" }}
            >
              <img
                src="/atf.svg"
                alt={`The Auto Finder`}
                style={{ maxHeight: "85px" }}
              />
            </Stack>
            <Typography fontWeight={700} variant="h5">
              Welcome Back
            </Typography>
            <TextField
              size="small"
              id="email"
              label="Email"
              variant="outlined"
              fullWidth
              name="email"
              //type="email"
            />
            {/* <TextField
              size="small"
              id="password"
              label="password"
              variant="outlined"
              fullWidth
              name="password"
              type="password"
            /> */}
            <FormControl
              size="small"
              id="password"
              label="password"
              fullWidth
              variant="outlined"
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                name="password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={isLoding}
            >
              {isLoding ? "submitting" : "login"}
            </Button>
          </Stack>
        </Paper>
      </Container>
    </React.Fragment>
  )
}
