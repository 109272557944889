import { Box, Button, Chip, IconButton, Stack } from "@mui/material"
import axios from "axios"
import React from "react"
import { useState } from "react"
import { URL } from "../../config/apis"
import useData from "../../context/Data"
import { formatPrice } from "../../helppers/formatters"
import PricesInput from "../inputs/PricesInput"
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd"
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded"

const styles = {
  fontSize: { xs: "0.7rem", md: "0.75rem", lg: "0.875rem" },
  px: { xs: 0.8, sm: 2, lg: 3 },
}

export default function Actions({
  setLeads,
  lead,
  setProgress,
  getLeads,
  type = "new",
}) {
  const { loading, setLoading, setShow, setErr, disabled } = useData()
  const [open, setOpen] = useState(false)
  const [counter, setCounter] = useState()

  const onAccept = async () => {
    setLoading(true)
    setProgress(true)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${lead?._id}/accept`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLeads((prev) => [...prev?.filter((l) => l?._id !== lead?._id)])
        setLoading(false)
        setProgress(false)
        setShow(true)
        getLeads()
        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    } catch (error) {
      setLoading(false)
      setProgress(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 5000)
    }
  }

  const onCounter = async () => {
    if (!counter || counter === 0) {
      setErr("Please add a offer amount")
      return setTimeout(() => {
        setErr(null)
      }, 2000)
    }
    setLoading(true)
    setProgress(true)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${lead?._id}/counter`,
        { counter },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLeads((prev) => [...prev?.filter((l) => l?._id !== lead?._id)])
        setLoading(false)
        setProgress(false)
        setShow(true)
        getLeads()
        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    } catch (error) {
      setLoading(false)
      setProgress(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 5000)
    }
  }

  const onDeniey = async () => {
    setLoading(true)
    setProgress(true)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${lead?._id}/deny`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLeads((prev) => [...prev?.filter((l) => l?._id !== lead?._id)])
        setLoading(false)
        setProgress(false)
        setShow(true)
        getLeads()
        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    } catch (error) {
      setLoading(false)
      setProgress(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 5000)
    }
  }

  const onInterestedIn = async () => {
    if (type === "interested") return
    setLoading(true)
    setProgress(true)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${lead?._id}/interested`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLeads((prev) => [...prev?.filter((l) => l?._id !== lead?._id)])
        setLoading(false)
        setProgress(false)
        setShow(true)
        getLeads()
        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    } catch (error) {
      setLoading(false)
      setProgress(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 5000)
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", md: "15% !important" },
          px: { xs: 5, md: 2 },
          py: { xs: 2, md: 1 },
          justifyContent: "center",
          alignItems: "center",
          flexShrink: 0,
        }}
      >
        {open ? (
          <Stack
            width={"100%"}
            direction={{ xs: "row", md: "column" }}
            spacing={2}
          >
            <PricesInput
              lable="New Bid"
              onValueChange={setCounter}
              value={counter}
              //  sx={{ maxWidth: "140px" }}
              disabled={loading || disabled}
            />
            <Button
              onClick={onCounter}
              disabled={loading || disabled}
              variant="outlined"
            >
              Send
            </Button>
            <Button
              onClick={() => {
                setOpen(false)
              }}
              disabled={loading}
              variant="text"
              color="error"
            >
              Cancel
            </Button>
          </Stack>
        ) : (
          <Stack
            alignItems={"center"}
            direction={{ xs: "row", md: "column" }}
            spacing={2}
          >
            {type === "interested" ? (
              <Chip
                icon={
                  <BookmarkAddedIcon
                    sx={{
                      color: "#ffa000 !important",
                      fontSize: { xs: "1.1rem", md:'1.3rem',  lg: "1.5rem" },
                    }}
                  />
                }
                label="Interested"
                variant="outlined"
                sx={{
                  borderRadius: 1,
                  fontSize: { xs: "0.7rem", md: "0.75rem", lg: "0.875rem" },
                  fontWeight: "500",
                  color: "#ffa000",
                  border: 0,
                }}
              />
            ) : (
              <Button
                startIcon={<BookmarkAddIcon />}
                onClick={onInterestedIn}
                disabled={loading || disabled}
                color="warning"
                sx={{
                  fontSize: { xs: "0.7rem", md: "0.75rem", lg: "0.875rem" },
                  color: "#ffa000 !important",
                  px: { xs: 0.8, lg: 1 },
                }}
                variant="outlined"
              >
                Interested
              </Button>
            )}
            <Button
              sx={styles}
              onClick={onAccept}
              disabled={loading || disabled}
              variant="contained"
            >
              Accept
            </Button>
            <Button
              onClick={() => {
                setOpen(true)
              }}
              disabled={loading || disabled}
              variant="outlined"
              sx={styles}
            >
              Bid Now
            </Button>
            <Button
              onClick={onDeniey}
              disabled={loading || disabled}
              variant="text"
              color="error"
              sx={styles}
            >
              Decline
            </Button>
          </Stack>
        )}
      </Box>
    </>
  )
}
