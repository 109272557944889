import React from "react"
import Slider from "@mui/material/Slider"
import { formatPrice } from "../../helppers/formatters"
import { Box, Button, MenuItem, Stack, Typography } from "@mui/material"
import SelectMenu from "../inputs/SelectMenu"
import { makesWithLogos } from "../../constants/makes"
import { sortOptions } from "../../constants/months"

function valuetext(value) {
  return `${formatPrice(value)}`
}
export default function LeadFilters({
  make,
  setMake,
  margin,
  setMargin,
  getLeads,
  sort,
  setSort,
}) {
  const handleChange = (event, newValue) => {
    setMargin(newValue)
  }
  return (
    <Stack
      flex={1}
      direction={"row"}
      pb={{ xs: 5, sm: 2 }}
      gap={3}
      flexWrap={"wrap"}
    >
      <SelectMenu
        handleChange={(v) => setMake(v)}
        label={"Makes"}
        value={make}
        options={makesWithLogos}
        sx={{ minWidth: { xs: 120, sm: 150 } }}
      >
        <MenuItem value={"All"}>All</MenuItem>
        {makesWithLogos &&
          makesWithLogos?.map((m, i) => (
            <MenuItem key={m.name + i} value={m.name}>
              {m.name}
            </MenuItem>
          ))}
      </SelectMenu>
      <Stack flex={1} sx={{ maxWidth: { xs: 300, md: 300 } }}>
        <Typography variant="body2">
          Margin:{" "}
          <span style={{ fontWeight: "600" }}>{`${formatPrice(
            margin[0]
          )} - ${formatPrice(margin[1])}`}</span>
        </Typography>
        <Slider
          getAriaLabel={() => "Margin range"}
          value={margin}
          onChange={handleChange}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          valueLabelFormat={valuetext}
          max={10000}
          step={50}
        />
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          position: { xs: "absolute", sm: "relative" },
          bottom: 5,
          left: 5,
        }}
      >
        <Button
          size="medium"
          onClick={getLeads}
          variant="contained"
          sx={{
            maxHeight: 40,
          }}
        >
          Filter
        </Button>

        <SelectMenu
          handleChange={(v) => setSort(v)}
          label={"Sort By"}
          value={sort}
          options={sortOptions}
          sx={{ minWidth: { xs: 120, sm: 150 } }}
        >
          {sortOptions &&
            sortOptions?.map((o, i) => (
              <MenuItem value={o.value} key={o?.value + i}>
                {o.name}
              </MenuItem>
            ))}
        </SelectMenu>
      </Stack>
    </Stack>
  )
}
