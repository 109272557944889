export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
export const currentMonth = new Date().getMonth()

export const sortOptions = [
  {
    name: "Price (lowest)",
    value: JSON.stringify({ sort: "prePrice", val: 1 }),
  },
  {
    name: "Price (Highest)",
    value: JSON.stringify({ sort: "prePrice", val: -1 }),
  },
  {
    name: "Margin (highest)",
    value: JSON.stringify({ sort: "margin", val: -1 }),
  },
  {
    name: "Age (Newest First)",
    value: JSON.stringify({ sort: "year", val: -1 }),
  },
  {
    name: "Age (Oldest First)",
    value: JSON.stringify({ sort: "year", val: 1 }),
  },
  { name: "Recent", value: JSON.stringify({ sort: "sentAt", val: -1 }) },
]
