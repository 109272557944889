import React from "react"
import axios from "axios"
import SearchDialog from "../components/search/SearchDialog"
import { URL } from "../config/apis"
import { useSearchParams } from "react-router-dom"

export default function SearchPage() {
  const [searchParams, setSearchParams] = useSearchParams()

  const [counters, setCounters] = React.useState([])
  const [myBids, setMyBids] = React.useState([])
  const [accepted, setAccepted] = React.useState([])
  const [appointments, setAppointments] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [isDone, setIsDone] = React.useState(false)
  const [err, setErr] = React.useState(null)
  const [query, setQuery] = React.useState(searchParams.get("query") || "")

  const getResults = async () => {
    if (query?.trim() === "") return
    setLoading(true)
    try {
      const res = await axios.post(
        `${URL}/search`,
        { query },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      setCounters(res.data.counters)
      setAccepted(res.data.accepted)
      setMyBids(res.data.myBids)
      setAppointments(res.data.appointments)
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
    }
    setLoading(false)
    setIsDone(true)
    setSearchParams({ query: query })
  }

  return (
    <SearchDialog
      counters={counters}
      myBids={myBids}
      accepted={accepted}
      loading={loading}
      err={err}
      query={query}
      setQuery={setQuery}
      isDone={isDone}
      getResults={getResults}
      setCounters={setCounters}
      setMyBids={setMyBids}
      setAccepted={setAccepted}
      appointments={appointments}
      setAppointments={setAppointments}
    />
  )
}
