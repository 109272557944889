import moment from "moment"
import momentTZ from "moment-timezone"

export const formatPrice = (price, sign = false) => {
  if (price == null || isNaN(price)) return "N/A"
  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 0,
    signDisplay: sign ? "exceptZero" : "auto",
  })
  return formatter.format(price)
}
export const formatDate = (date) => {
  return moment(date).format("DD/MM/YYYY")
}

export const formatDateTz = (date, tz) => {
  return momentTZ.tz(date, tz).format("DD/MM/YYYY @ h:mm A")
}
