import * as React from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"
import LineGrid from "./LineGrid"
import { formatPrice } from "../../helppers/formatters"
import Chip from "@mui/material/Chip"
import Actions from "./Actions"
import AcceptedActions from "./AcceptedActions"
import CounterActions from "./CounterActions"
import AppointmentsActions from "./AppointmentsActions"
import DeniedActions from "./DeniedActions"
import { LazyLoadImage } from "react-lazy-load-image-component"
import AutoCheckBox from "../global/autocheck/AutoCheckBox"
import Lightbox from "react-18-image-lightbox"
import "react-18-image-lightbox/style.css"
import useData from "../../context/Data"
import ConditionReport from "../global/condition/ConditionReport"

const splitStr = (str) => {
  if (!str) return
  let newStr = "..."
  if (!str.includes(" ")) {
    newStr = str?.slice(0, 3)
    return `${newStr} ...`
  }
  newStr = str.split(" ")[0]
  return `${newStr} ...`
}

export default function LeadCard({
  setOpen,
  lead,
  setLeads,
  type = "new",
  getLeads,
  extra = false,
}) {
  const { size } = useData()
  const [isCopied, setIsCopied] = React.useState(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [photoIndex, setPhotoIndex] = React.useState(0)

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand("copy", true, text)
    }
  }

  const handleCopyClick = (text, type) => {
    copyTextToClipboard(text)
      .then(() => {
        setIsCopied(type)
        setOpen(true)
        setTimeout(() => {
          setIsCopied(null)
          setOpen(false)
        }, 1500)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const images = React.useMemo(() => {
    if (!lead) return []
    if (!lead?.f_image) return lead?.images?.map((x) => x?.url)
    return [lead?.f_image?.url, ...lead?.images?.map((x) => x?.url)]
  }, [lead])

  const lost = React.useMemo(() => {
    if (lead?.isLost) return true
    if (lead?.lead?.isTrash) return true
    return false
  }, [lead?.isLost, lead?.isTrash])

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          opacity: loading ? 0.6 : type === "accepted" && lost ? 0.7 : 1,
          bgcolor: extra ? "#dcfc6f6b" : "inherit",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardMedia className="cmedia" sx={{ position: "relative", p: 0 }}>
          <LazyLoadImage
            src={lead?.f_image?.url}
            style={{
              objectFit: "cover",
              objectPosition: "center",
              width: size <= 1000 ? "100%" : "300px",
              flex: "none",
              cursor: "pointer",
            }}
            height="230px"
            width={size <= 1000 ? "100%" : "300px"}
            alt={lead?.title}
            placeholderSrc="/placeholder.png"
          />
          <span
            onClick={() => setIsOpen(true)}
            style={{
              position: "absolute",
              inset: 0,
              justifyContent: "center",
              alignItems: "center",
            }}
            className="showImg"
          >
            {" "}
            Click for more photos
          </span>
          <Stack
            display={{ xs: "flex", md: "none" }}
            position="absolute"
            alignItems={"center"}
            sx={{ bottom: 0, right: 0, bgcolor: "#0059ff", color: "#fff" }}
            direction="row"
            spacing={1}
            px={2}
            py={0.5}
          >
            <Typography sx={{ flex: "none" }} component="div" variant="body2">
              {type === "accepted" ? "Agreed Price" : "Sellers Price"}:
            </Typography>
            <Typography
              sx={{ flex: "none" }}
              component="div"
              variant="body1"
              fontWeight={700}
            >
              {type === "accepted"
                ? formatPrice(lead?.agreedPrice)
                : formatPrice(lead?.prePrice)}
            </Typography>
          </Stack>
        </CardMedia>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            //   gap: 2,
            justifyContent: "center",
            maxWidth: { xs: "100%", lg: "58.9%" },
          }}
        >
          <CardContent sx={{ flex: "1 0 auto", position: "relative" }}>
            <Stack spacing={2}>
              <Stack
                spacing={5}
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "start", md: "center" }}
              >
                <Stack maxWidth={{ xs: "100%", lg: "80%" }}>
                  <Typography
                    fontWeight={600}
                    sx={{
                      flexGrow: 1,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    component="div"
                    variant={size <= 500 ? "body1" : size <= 1000 ? "h5" : "h6"}
                  >
                    {lead?.title}
                  </Typography>
                  <Stack flexWrap={"wrap"} direction={"row"} spacing={2}>
                    <Typography
                      sx={{ flex: "none" }}
                      component="div"
                      variant="body2"
                    >
                      Transmission: {lead?.transmission || "N/A"}
                    </Typography>
                    <Divider orientation="vertical" flexItem />
                    <Typography
                      sx={{ flex: "none" }}
                      component="div"
                      variant="body2"
                    >
                      Engine: {lead?.engine || "N/A"}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack display={{ xs: "none", md: "flex" }} alignItems={"end"}>
                  <Typography
                    sx={{ flex: "none" }}
                    component="div"
                    variant="h5"
                    fontWeight={700}
                  >
                    {type === "accepted"
                      ? formatPrice(lead?.agreedPrice)
                      : formatPrice(lead?.prePrice)}
                  </Typography>
                  <Typography
                    sx={{ flex: "none" }}
                    component="div"
                    variant="body2"
                  >
                    {type === "accepted" ? "Agreed Price" : "Price"}
                  </Typography>
                </Stack>
              </Stack>
              <Stack spacing={2}>
                <Stack flexWrap={"wrap"} direction="row" gap={1}>
                  <Chip
                    label={lead?.vrm}
                    sx={{
                      px: 0.5,
                      py: 0.5,
                      fontSize: { xs: "0.7125rem", md: "0.8125rem" },
                    }}
                    size="small"
                    color={isCopied === "vrm" ? "success" : "primary"}
                    onClick={(e) =>
                      handleCopyClick(e.currentTarget.innerText, "vrm")
                    }
                  />

                  {lead?.mileage && (
                    <Chip
                      label={
                        lead?.mileage
                          ? `${lead?.mileage?.toLocaleString()} mi`
                          : "N/A"
                      }
                      sx={{
                        px: 0.5,
                        py: 0.5,
                        fontSize: { xs: "0.7125rem", md: "0.8125rem" },
                      }}
                      size="small"
                      color="primary"
                    />
                  )}
                  {lead?.condition?.html && (
                    <ConditionReport condition={lead?.condition} />
                  )}
                </Stack>
                <Stack
                  direction={"row"}
                  // justifyContent="space-between"
                  alignItems={"center"}
                  spacing={{ xs: 2, lg: 5 }}
                >
                  <LineGrid
                    lable="Recommended Retail"
                    value={formatPrice(lead?.at_retail)}
                    logo={size > 500}
                  />

                  {lead?.at_retail && lead?.prePrice && (
                    <LineGrid
                      lable="Margin"
                      value={formatPrice(
                        lead?.at_retail - lead?.prePrice,
                        true
                      )}
                      color={
                        lead?.at_retail - lead?.prePrice === 0
                          ? "inherit"
                          : lead?.at_retail - lead?.prePrice > 0
                          ? "green"
                          : "red"
                      }
                    />
                  )}
                </Stack>
                <Stack
                  direction="row"
                  flexWrap={"wrap"}
                  columnGap={1}
                  rowGap={1}
                >
                  <AutoCheckBox autoCheck={lead?.autoCheck} />
                  {/* {lead?.condition?.html && (
                    <ConditionReport condition={lead?.condition} />
                  )} */}

                  {/* {lead?.titleStatus && (
                    <Chip
                      label={lead?.titleStatus}
                      sx={{
                        px: 0.5,
                        py: 0.5,
                        fontSize: { xs: "0.7125rem", md: "0.8125rem" },
                      }}
                      size="small"
                      color="primary"
                    />
                  )} */}

                  {lead?.postCode && (
                    <Chip
                      label={lead?.postCode}
                      sx={{
                        px: 0.5,
                        py: 0.5,
                        fontSize: { xs: "0.7125rem", md: "0.8125rem" },
                      }}
                      size="small"
                      color="primary"
                    />
                  )}
                  {lead?.postalTown && (
                    <Chip
                      label={lead?.postalTown}
                      sx={{
                        px: 0.5,
                        py: 0.5,
                        fontSize: { xs: "0.7125rem", md: "0.8125rem" },
                      }}
                      size="small"
                      color="primary"
                    />
                  )}
                  {lead?.prevOwners && (
                    <Chip
                      label={`Owners: ${lead?.prevOwners}`}
                      sx={{
                        px: 0.5,
                        py: 0.5,
                        fontSize: { xs: "0.7125rem", md: "0.8125rem" },
                      }}
                      size="small"
                      color="primary"
                    />
                  )}
                  {lead?.sHistory && (
                    <Chip
                      label={lead?.sHistory}
                      sx={{
                        px: 0.5,
                        py: 0.5,
                        fontSize: { xs: "0.7125rem", md: "0.8125rem" },
                      }}
                      size="small"
                      color="primary"
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
            {type === "accepted" && lost && (
              <div className="sold">
                <img src="/lost.png" style={{ height: "90%", width: "auto" }} />
              </div>
            )}
          </CardContent>
        </Box>
        <Divider
          orientation={size <= 1000 ? "horizontal" : "vertical"}
          variant="middle"
          flexItem
        />
        {type === "new" || type === "interested" ? (
          <Actions
            getLeads={getLeads}
            lead={lead}
            setLeads={setLeads}
            setProgress={setLoading}
            type={type}
          />
        ) : type === "accepted" ? (
          <AcceptedActions
            lead={lead}
            setLeads={setLeads}
            setProgress={setLoading}
            getLeads={getLeads}
          />
        ) : type === "counter" ? (
          <CounterActions
            lead={lead}
            setLeads={setLeads}
            setProgress={setLoading}
            getLeads={getLeads}
          />
        ) : type === "appointment" ? (
          <AppointmentsActions
            lead={lead}
            setLeads={setLeads}
            setProgress={setLoading}
          />
        ) : (
          type === "denied" && (
            <DeniedActions
              lead={lead}
              setLeads={setLeads}
              setProgress={setLoading}
              getLeads={getLeads}
            />
          )
        )}
      </Card>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((prev) => (prev + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((prev) => (prev + 1) % images.length)
          }
          style={{ zIndex: 99999 }}
        />
      )}
    </>
  )
}
