import * as React from "react"

import Chip from "@mui/material/Chip"
import DoneIcon from "@mui/icons-material/Done"
import ErrorIcon from "@mui/icons-material/Error"
import PopUpDialog from "../PopUpDialog"
import AutoCheckData from "./AutoCheckData"

export default function AutoCheckBox({ autoCheck }) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <Chip
        label="HPI CLEAR"
        sx={{ px: 0.5, py: 0.5 }}
        size="small"
        deleteIcon={true ? <DoneIcon /> : <ErrorIcon />}
        color={true ? "success" : "error"}
        onDelete={() => {}}
        onClick={() => setOpen(true)}
      />

      <PopUpDialog  open={open} setOpen={setOpen}>
        {open && <AutoCheckData autoCheck={autoCheck} />}
      </PopUpDialog>
    </>
  )
}
